/**
 * Returns true if the given brand is neosurf
 *
 * @param brand
 * @returns
 */
export function brandIsNeosurf({ brand }) {
    if (!brand) {
        return false;
    }
    return brand.slug === 'neosurf';
}

/**
 * Returns true if the given product is neosurf
 *
 * @param product
 * @returns
 */
export function productIsNeosurf({ product }) {
    if (!product) {
        return false;
    }
    return brandIsNeosurf({ brand: product.brand });
}
/**
 * Returns true if the given list of products contains neosurf
 *
 * @param cartItems
 * @returns
 */
export function cartHasNeosurf({ cartItems }) {
    if (!cartItems) {
        return false;
    }
    return !!cartItems.find(item => !!productIsNeosurf({ product: item.product }));
}
