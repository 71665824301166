import { discountIsValidForCart } from '@dundle/utils/cart';
import { DEFAULT_AZURE_BASE_URL } from '../../../../config/constants';
import { postJSON, getJSON } from '../utils/json';
import { SHA256 } from '~/plugins/sha256';

export default ({ baseURL, providers, app }) => ({
    contact(form) {
        form.append('language', providers.language());
        form.append('region', providers.region());
        form.append('domain', providers.domain());
        return postJSON(`${baseURL}/old-api/actions/contact`, form, { 'Content-Type': 'multipart/form-data' });
    },

    pushRating(transactionId, rating, message) {
        return postJSON(`${baseURL}/old-api/actions/push-rating`, {
            transactionId,
            rating,
            message,
        });
    },

    checkUser(name, email, pasted) {
        return postJSON(`${baseURL}/old-api/actions/check-user`, {
            name,
            email,
            pasted,
            session: {
                id: providers.sessionId(),
                utm: providers.sessionUTM(),
            },
        });
    },

    validateCodiceFiscale(codiceFiscale) {
        return postJSON(`${DEFAULT_AZURE_BASE_URL}/validation/codice-fiscale`, {
            codiceFiscale,
        });
    },

    exchange(transactionId, productId) {
        return getJSON(`${baseURL}/old-api/actions/exchange/${transactionId}/${productId}/${app.$locale.language}`);
    },

    addReferrer(affiliateId) {
        return postJSON(`${baseURL}/old-api/actions/add-referrer`, {
            affiliateId,
        });
    },
    async checkDiscount(code) {
        if (
            !discountIsValidForCart({ discountCode: await SHA256(code), cartItems: app.store.getters['cart/getItems'] })
        ) {
            return Promise.resolve({
                value: 0,
            });
        }
        return postJSON(`${baseURL}/old-api/actions/check-discount`, {
            discountCode: code,
        });
    },
    verifyEmail(secureToken, transactionId) {
        return postJSON(`${baseURL}/old-api/actions/verify-email`, {
            secureToken,
            transactionId,
        });
    },
    shuftiVerificationUrl(transactionId) {
        return getJSON(`${baseURL}/old-api/shufti-verification-url/${transactionId}`);
    },
    redirectReturn(transactionId, amountTried) {
        return getJSON(`${baseURL}/old-api/order-redirection/${transactionId}?amountTried=${amountTried}`);
    },
});
