/**
 * Cleans the product information and returns a standardized object.
 *
 * @param {Object} product - The product object to be cleaned.
 * @param {Object} brand - The brand name of the product.
 * @returns {{ productId: string, brandName: string, productCategory: string, productPrice: (number|undefined) }} - The cleaned product information.
 */
function productCleaner(product, brand) {
    // Sometimes there is an object around the received product.
    if (product?.product) {
        product = product.product;
    }

    const brandName = (
        brand?.name ||
        brand?.brandName?.name ||
        product?.brand?.name ||
        product?.brandName?.name ||
        product?.name ||
        ''
    ).toLowerCase();

    const productCategory = brand?.category || product?.brand?.category || product?.category || '';

    const productId = product?.productId || `${brandName?.toLowerCase()}_brand`;

    return { productId, brandName, productCategory, productPrice: product?.price ?? 0 };
}

/**
 *
 * @param {*} cartItems
 */
export function convertCartItemsToGoogleAnalyticsItemSpec(cartItems = []) {
    const gaProducts = cartItems.map(cartItem => {
        const { productId, brandName, productCategory, productPrice } = productCleaner(cartItem.product, undefined);

        const gaProduct = {
            item_id: productId, // "amazon-code-10-eur-de"
            item_name: brandName, // Amazon
            item_brand: brandName, // Amazon
            item_category: productCategory, // giftcards
            price: productPrice,
            quantity: cartItem.quantity || 1,
        };

        return gaProduct;
    });
    const totalPrice = gaProducts.reduce((total, product) => total + product.price * product.quantity, 0);
    return { gaProducts, totalPrice };
}

/**
 *
 * @param {*} products
 * @param {*} brand
 * @param {*} quantities
 * @returns {{gaProducts: { item_id: string; item_name: string;item_brand: string; item_category: string;}[], totalPrice: number}}
 */
export function convertProductsToGoogleAnalyticsItemSpec(products = [], brand = false) {
    const gaProducts = products?.map(product => {
        const { productId, brandName, productCategory, productPrice } = productCleaner(product, brand);

        const gaProduct = {
            item_id: productId, // "amazon-code-10-eur-de"
            item_name: brandName, // Amazon
            item_brand: brandName, // Amazon
            item_category: productCategory, // giftcards
            quantity: product.quantity || 1,
            price: productPrice,
        };

        return gaProduct;
    });

    const totalPrice = gaProducts.reduce((total, product) => total + product.price, 0);
    return { gaProducts, totalPrice };
}

export function convertToFirehoseSpec(products = []) {
    const firehoseProducts = products.map(product => {
        const { productId, productPrice } = productCleaner(product);

        const firehoseProduct = {
            id: productId,
            priceInEuro: productPrice,
            inStock: product.stock > 0,
            quantity: product.quantity || 1,
        };
        return firehoseProduct;
    });
    return { firehoseProducts };
}

/**
 *
 * @param {*} cartItems
 */
export function convertCartItemsToFacebookContentSpec(cartItems = []) {
    const productData = cartItems.map(cartItem => ({
        ...productCleaner(cartItem.product, undefined),
        quantity: cartItem.quantity,
    }));

    const contentIDs = productData.map(product => product.brandName);
    const contents = productData.map(product => ({ id: product.brandName, quantity: product.quantity }));
    const totalPrice = productData.reduce((total, product) => total + product.productPrice * product.quantity, 0);
    const contentCategory = productData[0]?.productCategory;

    const contentType = contents.length > 1 ? 'product_group' : 'product';

    return {
        content_category: contentCategory,
        content_ids: contentIDs,
        content_type: contentType,
        contents,
        currency: 'EUR',
        num_items: contentIDs.length,
        value: totalPrice, // not sure it this is correct, there is nothing being done with the quantities..
    };
}

export function convertProductsToFacebookContentSpec(products = [], brand = false) {
    const productData = products.map(product => productCleaner(product, brand));

    const contentIDs = productData.map(product => product.brandName);
    const contents = productData.map(product => ({ id: product.brandName, quantity: product.quantity }));
    const totalPrice = productData.reduce((total, product) => total + product.productPrice * product.quantity, 0);
    const contentCategory = productData[0]?.productCategory;

    const contentType = contents.length > 1 ? 'product_group' : 'product';

    return {
        content_category: contentCategory,
        content_ids: contentIDs,
        content_type: contentType,
        contents,
        currency: 'EUR',
        num_items: contentIDs.length,
        value: totalPrice, // not sure it this is correct, there is nothing being done with the quantities..
    };
}

/**
 * Converts float to Micros. Needed for the sift api
 * @param {number} value
 * @param {number} currencyExchangeRate
 * @returns number
 */
export function convertFloatToMicros(value, currencyExchangeRate = 1) {
    return Math.round(currencyExchangeRate * value * 1000000);
}
