import { sum } from '@dundle/utils/array';
import Vue from 'vue';
import { definePlugin } from '@dundle/utils/nuxt';
import { cartHasGooglePlay } from '@dundle/utils/cart';
import { TEST_DOMAIN } from '~/config/constants';
import vatRates from '~/config/data/vat-of-country';

const AB_COOKIE = 'ab';
const VISIT_ID_COOKIE = 'dundle.visitId';

export default definePlugin(({ app, store, route }, inject) => {
    inject(
        'cart',
        new Vue({
            data() {
                return {
                    onLoadedlisteners: [],
                    euroRegions: [
                        'ad',
                        'be',
                        'cy',
                        'de',
                        'ee',
                        'fi',
                        'fr',
                        'gr',
                        'ie',
                        'it',
                        'lt',
                        'lv',
                        'ma',
                        'mc',
                        'nl',
                        'at',
                        'pt',
                        'sm',
                        'si',
                        'sk',
                        'es',
                        'va',
                    ],
                };
            },

            computed: {
                isLoaded() {
                    return store.getters['cart/isLoaded'];
                },

                items() {
                    return store.getters['cart/getItems'];
                },

                discountCode() {
                    return store.getters['cart/getDiscountCode'];
                },

                codiceFiscale() {
                    return store.getters['user/getCodiceFiscale'];
                },

                paymentMethods() {
                    return store.getters['data/getMethods'];
                },

                detectedRegion() {
                    return store.getters['locale/detectedRegion'];
                },

                gclid() {
                    return store.getters['session/gclid'];
                },

                sessionUTM() {
                    return store.getters['session/utm'];
                },

                subTotal() {
                    return sum(this.items.map(item => this.getItemTotalPrice(item)));
                },

                receiveNewsletter() {
                    return store.getters['user/getReceiveNewsletter'];
                },
            },

            watch: {
                isLoaded() {
                    this.onLoadedlisteners.forEach(listener => listener());
                },
            },

            methods: {
                requestLoadedCallback(func) {
                    this.onLoadedlisteners.push(func);
                },

                contains(brandSlug) {
                    return !!this.items.find(item => {
                        return item.product.brand.slug === brandSlug;
                    });
                },

                getVAT() {
                    return sum(this.items.map(item => this.getItemVAT(item)));
                },

                getPaymentMethod() {
                    return app.store.state.cart.paymentMethod;
                },

                getTransactionCosts(method = this.getPaymentMethod()) {
                    if (method) {
                        const fee = this.getActualTransactionCosts(method);

                        if (this.calculateFreeFee(fee)) return 0;

                        // DEV-6009: when the user has below 5 euro transaction costs, we calculate a percentage of 2 over the subTotal instead of the amount returned by the paymentmethods.json in the CDN
                        if (this.usePaypalTransactionCostsException({ method, fee })) {
                            const factor = 2 / 100;
                            const lowFee = this.subTotal * factor;
                            return lowFee;
                        }

                        return fee;
                    } else {
                        return 0;
                    }
                },
                getActualTransactionCosts(method = this.getPaymentMethod()) {
                    if (method) {
                        const base = method.transactionCosts.amount;
                        const factor = method.transactionCosts.percentage / 100;

                        const fee = base + this.subTotal * factor;

                        return fee;
                    } else {
                        return 0;
                    }
                },
                usePaypalTransactionCostsException({ method, fee }) {
                    // DEV-6009: when the user has below 5 euro transaction costs, we calculate a percentage of 2 over the subTotal instead of the amount returned by the paymentmethods.json in the CDN
                    if (
                        (method.id === 'paypal' || method.id === 'dinrex.paypal') &&
                        this.detectedRegion === 'de' &&
                        fee < 5
                    ) {
                        return true;
                    }
                    return false;
                },

                getCartTotal() {
                    return Math.max(0, this.getSubTotalWithVat() - this.getDiscount());
                },

                getCartTotalWithTransactionCosts() {
                    return Math.max(0, this.getSubTotalWithVat() + this.getTransactionCosts() - this.getDiscount());
                },

                getTotalWithoutTransactioncost() {
                    return Math.max(0, this.getSubTotalWithVat() - this.getDiscount());
                },

                getSubTotalWithVat() {
                    return this.subTotal + this.getVAT();
                },

                getDiscount() {
                    const discount = store.state.cart.discount;

                    if (Object.prototype.hasOwnProperty.call(discount, 'value')) {
                        return discount.value;
                    } else if (Object.prototype.hasOwnProperty.call(discount, 'percentage')) {
                        return Object.prototype.hasOwnProperty.call(discount, 'amountPercentageMax')
                            ? Math.min((this.subTotal / 100) * discount.percentage, discount.amountPercentageMax)
                            : (this.subTotal / 100) * discount.percentage;
                    }
                    return 0;
                },

                getItemPrice(item) {
                    return app.$product.getPrice(item.product) * item.quantity;
                },

                getItemTotalPrice(item) {
                    return item.product.price * item.quantity;
                },

                getItemServiceCosts(item) {
                    return app.$product.getServiceCosts(item.product) * item.quantity;
                },

                getItemVAT(item) {
                    return item.product.isVat ? this.calculateVAT(item.product.price) * item.quantity : 0;
                },

                calculateVAT(value) {
                    const regionVat = vatRates[app.$locale.regio];

                    return regionVat ? (value / 100) * regionVat : 0;
                },

                calculateFreeFee(fee) {
                    // if there is a google play in the cart, it's always free
                    if (cartHasGooglePlay({ cartItems: this.items })) {
                        return true;
                    }
                    // if the fee is below 0.18 cents, transactions are free
                    if (fee <= 0.18) return true;
                    return false;
                },

                async checkout(methodId, options = {}, redirect = true) {
                    const fingerprint = app.$fingerprint.get();

                    const cartItems = [];

                    // Convert items to API readable format:
                    this.items.forEach(item => {
                        // API does not support quantity:
                        if (item.gift) {
                            cartItems.push({
                                productId: item.product.productId,
                                gift: item.gift,
                            });
                        } else {
                            for (let i = 0; i < item.quantity; i++) {
                                cartItems.push({
                                    productId: item.product.productId,
                                });
                            }
                        }
                    });

                    const extraData = {
                        returnUrl: app.$locale
                            .url({
                                name: 'order-id',
                                params: {
                                    id: ':orderId',
                                },
                                query: {
                                    email: app.$auth.user.email,
                                },
                            })
                            .replace(':orderid', ':orderId'),
                        cancelUrl: app.$locale.url('checkout'),
                    };

                    if (window.location.hostname === TEST_DOMAIN) {
                        extraData.returnUrl = extraData.returnUrl.replace('dundle.com', TEST_DOMAIN);
                        extraData.cancelUrl = extraData.cancelUrl.replace('dundle.com', TEST_DOMAIN);
                    }

                    if (app.$security.isEnabled) {
                        extraData.enableRuleCheck = true;
                        extraData.enableCreditCardCheck = true;
                    }

                    const trxFeeExperimentAmount = cartHasGooglePlay({ cartItems: this.items })
                        ? this.getActualTransactionCosts()
                        : 0.18;

                    try {
                        const order = await app.$api.order.checkout({
                            ...extraData,
                            name: app.$auth.user.name,
                            email: app.$auth.user.email,
                            codiceFiscale: this.codiceFiscale || undefined,
                            strategy: app.$auth.strategy.name,
                            user: app.$auth.user,
                            methodId,
                            discountCode: this.discountCode,
                            cartItems,
                            // "creditCardData": this.creditCardData,
                            meta: {
                                ...options,
                                // Paypal experiment, remove once experiment ends
                                trxFeeExperimentAmount,
                                vatRegionSetting: app.$locale.region,
                                ipRegion: this.detectedRegion,
                                fp1: fingerprint,
                                gclid: this.gclid,
                                ga4_session_id: app.$analytics.getSessionId(),
                                visitId: app.$cookies.get(VISIT_ID_COOKIE),
                                ab: app.$cookies.get(AB_COOKIE),
                                utm: this.sessionUTM,
                            },
                            analyticsUserProperties: app.$analytics.getAnalyticsUserProperties(),
                        });

                        // subscribe to newsletter if user ticked box
                        if (this.receiveNewsletter) {
                            const contact = {
                                email: app.$auth.user.email,
                                attributes: {
                                    REGION: app.$locale.region,
                                    LANGUAGE: app.$locale.language,
                                    SUBSCRIBED_FROM: 'delivery',
                                },
                            };
                            await app.$api.newsletter.addNewContact(contact);
                        }

                        if (redirect) {
                            app.router.navigate(
                                app.$locale.path({
                                    name: 'order-id',
                                    params: {
                                        id: order.orderId,
                                    },
                                })
                            );
                        }

                        return order;
                    } catch (error) {
                        const { data: response } = error.response;
                        const declineReason = response?.statusCode === 400 && response?.message[0]?.reasonCode;

                        if (!declineReason) {
                            app.router.navigate(app.$locale.path('warning'));
                        }

                        switch (declineReason) {
                            case 'MANY_ATTEMPTS':
                                // test with: many_attempts@korsit.com
                                app.router.navigate(app.$locale.path({ name: 'warning-attempts' }));
                                break;
                            case 'BLACKLISTED_PRODUCTS':
                                // blocked country
                                // test with: blacklisted_products@korsit.com
                                app.router.navigate(app.$locale.path({ name: 'warning-region-restricted' }));
                                break;
                            case 'LIMIT':
                                // General decline
                                // test with: velocity_reached@korsit.com
                                app.router.navigate(app.$locale.path({ name: 'warning-limit' }));
                                break;
                            case 'VELOCITY_REACHED':
                                // General decline
                                // test with: velocity_reached@korsit.com
                                app.router.navigate(app.$locale.path({ name: 'warning-limit' }));
                                break;
                            case 'BLACKLISTED_USER':
                                // User banned
                                // test with: blacklisted_user@korsit.com
                                app.router.navigate(app.$locale.path({ name: 'warning-blocked' }));
                                break;
                            case 'USER_HAS_CHARGEBACK':
                                // test with: user_has_chargeback@korsit.com
                                app.router.navigate(app.$locale.path({ name: 'warning-disputes' }));
                                break;
                            case 'GENERAL_DECLINE':
                                // sift / score too high
                                // test with: general_decline@korsit.com
                                app.router.navigate(app.$locale.path({ name: 'warning-flagged' }));
                                break;
                            case 'KLARNA_LIMIT_REACHED':
                                app.router.navigate(app.$locale.path({ name: 'warning-klarna-unpaid' }));
                                break;
                            case 'VELOCITY_PAYSAFE_REACHED':
                                app.router.navigate(app.$locale.path({ name: 'warning-paysafe-velocity' }));
                                break;
                            default:
                                app.router.navigate(app.$locale.path({ name: 'warning-undefined' }));
                                break;
                        }
                    }
                },
            },
        })
    );
});
