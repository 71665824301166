const paysafecardExcludedDiscountCodes = ['97a27128662e78ee47662a4ceadfcdb567cc908fa2356073aa4f02188faf646a'];

/**
 * Returns true if the given brand is paysafecard
 *
 * @param brand
 * @returns
 */
export function brandIsPaysafecard({ brand }) {
    if (!brand) {
        return false;
    }
    return brand.slug === 'paysafecard';
}

/**
 * Returns true if the given product is paysafecard
 *
 * @param product
 * @returns
 */
export function productIsPaysafecard({ product }) {
    if (!product) {
        return false;
    }
    return brandIsPaysafecard({ brand: product.brand });
}

/**
 * Returns true if the given list of products contains paysafecard
 *
 * @param cartItems
 * @returns
 */
export function cartHasPaysafeCard({ cartItems }) {
    if (!cartItems) {
        return false;
    }
    return !!cartItems.find(item => !!productIsPaysafecard({ product: item.product }));
}

export function discountIsValidForPaysafecard({ discountCode }) {
    return !paysafecardExcludedDiscountCodes.find(code => code.toLocaleLowerCase() === discountCode.toLowerCase());
}

export function discountIsValidForCart({ discountCode, cartItems }) {
    if (!discountIsValidForPaysafecard({ discountCode }) && cartHasPaysafeCard({ cartItems })) return false;
    return true;
}
