import Vue from 'vue';

export default ({ app, route, store }, inject) => {
    Vue.component('nuxt-resource', {
        props: {
            src: {
                type: String,
            },

            fullURL: {
                type: Boolean,
                default: false,
            },

            lazy: {
                type: Boolean,
                default: false,
            },

            srcset: {
                type: String,
            },

            sizes: {
                type: String,
            },

            // Has to be a number to be able to do calculations
            // so don't use percentages
            width: {
                type: Number,
                required: false,
            },

            // Has to be a number to be able to do calculations
            // so don't use percentages
            height: {
                type: Number,
                required: false,
            },

            // Always pair ratio with width OR height. Not both!
            // when both width/height are set, ratio will not be used
            ratio: {
                type: String,
                default: undefined,
                required: false,
            },
        },

        data() {
            return {
                observer: null,
                resourceURL: 'https://cdn.dundle.com/resources/',
            };
        },

        computed: {
            classes() {
                return ['nuxt-resource'];
            },

            imageURL() {
                return this.fullURL ? this.src : this.resourceURL + this.src;
            },

            imageSrcset() {
                if (this.srcset) {
                    if (this.fullURL) {
                        return this.srcset;
                    } else {
                        const srcset = this.srcset.split(', ');
                        return srcset.map(src => this.resourceURL + src).join(', ');
                    }
                } else {
                    return null;
                }
            },

            ratioCalculation() {
                if (this.ratio) {
                    const separated = this.ratio.split(':');
                    return separated[1] / separated[0];
                }
                return undefined;
            },

            calculatedWidth() {
                // always return a number, will be extended with 'px'
                if (this.width) return this.width;
                if (this.height) return this.calculatedHeight * this.ratioCalculation;
                return undefined;
            },

            calculatedHeight() {
                // always return a number, will be extended with 'px'
                if (this.height) return this.height;
                if (this.width) return this.calculatedWidth * this.ratioCalculation;
                return undefined;
            },
        },

        render(c) {
            return c('img', {
                class: this.classes,
                attrs: {
                    ...this.$attrs,
                    src: this.imageURL,
                    loading: this.lazy ? 'lazy' : 'auto',
                    sizes: this.sizes,
                    srcset: this.imageSrcset,
                    width: this.calculatedWidth ? `${this.calculatedWidth}px` : undefined,
                    height: this.calculatedHeight ? `${this.calculatedHeight}px` : undefined,
                    style: this.ratio ? 'object-fit: cover;' : undefined, // to prevent awkward image squeezing
                },
            });
        },
    });
};
