import Vue from 'vue';
import { definePlugin } from '@dundle/utils/nuxt';
import { brandIsNeosurf, brandIsPaysafecard } from '~/utils/cart/';

export default definePlugin(({ app }, inject) => {
    inject(
        'brand',
        new Vue({
            methods: {
                cheapestBrandValue(brand) {
                    // fallback to empty string. without products.length it breaked format currency functions 'from' param
                    if (brand.products.length) {
                        const minProductPrice = Math.min(...brand.products.map(product => product.faceValue));
                        const productCurrency = brand.products.length ? brand.products[0].currencyCode : undefined;
                        return app.$formatCurrency(minProductPrice, {
                            from: productCurrency,
                            to: productCurrency,
                            rounded: true,
                        });
                    }
                    return '';
                },
                getRoute(brand) {
                    return {
                        name: 'brand',
                        params: {
                            brand: brand.slug,
                        },
                        trailingSlash: true,
                    };
                },

                isPaysafecard(brand) {
                    return brandIsPaysafecard({ brand });
                },
                isNeosurf(brand) {
                    return brandIsNeosurf({ brand });
                },

                getPath(brand) {
                    return app.$locale.path(this.getRoute(brand));
                },

                getManualPath(brand) {
                    return app.$locale.path({
                        name: 'manual-brand',
                        params: {
                            brand: brand.slug,
                        },
                        trailingSlash: true,
                    });
                },

                getImage(brand) {
                    return `images/brands/thumbnails/${brand.slug}.svg`;
                },

                getImage200(brand) {
                    return `images/products/200w/${brand.slug}-200w.png`;
                },

                getImage340(brand) {
                    return `images/products/340w/${brand.slug}-340w.png`;
                },

                getGoogleShoppingImage(brand) {
                    return `https://cdn.dundle.com/resources/images/products/product-google-shopping/${brand.slug}.png`;
                },

                getName(brand) {
                    if (typeof brand.productName === 'string') {
                        return brand.productName.replace('{value}', '').replace('{customName}', 'Amazon');
                    }
                    if (
                        typeof brand.productName === 'object' &&
                        typeof brand.productName.value === 'string' &&
                        brand.productName.value !== ''
                    ) {
                        return brand.productName.value.replace('{value}', '').replace('{customName}', 'Amazon');
                    }
                    return brand.name;
                },

                getDescription(brand) {
                    return brand.alt;
                },

                getCheapestProductPrice(brand) {
                    return brand.products && brand.products.length
                        ? app.$formatCurrency(Math.min(...brand.products.map(p => Number(p.faceValue))))
                        : 0;
                },

                allowsCrossBorder(brand) {
                    const brandsWithoutCrossBorder = ['paysafecard', 'abon'];
                    if (brandsWithoutCrossBorder.includes(brand)) {
                        return false;
                    } else {
                        return true;
                    }
                },
            },
        })
    );
});
